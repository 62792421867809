import type { Query as Query_yctcka } from '../../pages/search/[city]/[slug]/index.page';
import type { Query as Query_unfaxq } from '../../pages/search/[city]/index.page';

export const pagesPath = {
  $404: {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash }),
  },
  for_partners: {
    $url: (url?: { hash?: string | undefined } | undefined) => ({
      pathname: '/for-partners' as const,
      hash: url?.hash,
    }),
  },
  personal_account: {
    $url: (url?: { hash?: string | undefined } | undefined) => ({
      pathname: '/personal-account' as const,
      hash: url?.hash,
    }),
  },
  search: {
    _city: (city: string | number) => ({
      _slug: (slug: string | number) => ({
        $url: (url: { query: Query_yctcka; hash?: string | undefined }) => ({
          pathname: '/search/[city]/[slug]' as const,
          query: { city, slug, ...url.query },
          hash: url.hash,
        }),
      }),
      $url: (url: { query: Query_unfaxq; hash?: string | undefined }) => ({
        pathname: '/search/[city]' as const,
        query: { city, ...url.query },
        hash: url.hash,
      }),
    }),
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash }),
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  favicon_ico: '/favicon.ico',
  fonts: {
    Montserrat_Black_ttf: '/fonts/Montserrat-Black.ttf',
    Montserrat_ExtraBold_ttf: '/fonts/Montserrat-ExtraBold.ttf',
    Montserrat_Medium_ttf: '/fonts/Montserrat-Medium.ttf',
    Montserrat_Regular_ttf: '/fonts/Montserrat-Regular.ttf',
    Montserrat_SemiBold_ttf: '/fonts/Montserrat-SemiBold.ttf',
  },
  images: {
    bg_img_courts_png: '/images/bg-img-courts.png',
    calendar_icon_svg: '/images/calendar-icon.svg',
    checkbox_icon_svg: '/images/checkbox-icon.svg',
    chevron_icon_white_svg: '/images/chevron-icon-white.svg',
    chevron_icon_svg: '/images/chevron-icon.svg',
    closed_eye_svg: '/images/closed-eye.svg',
    club_page_geo_icon_svg: '/images/club-page-geo-icon.svg',
    club_page_info_icon_svg: '/images/club-page-info-icon.svg',
    club_page_phone_icon_svg: '/images/club-page-phone-icon.svg',
    club_page_tag_icon_svg: '/images/club-page-tag-icon.svg',
    filters_court_grass_svg: '/images/filters-court-grass.svg',
    filters_court_hard_svg: '/images/filters-court-hard.svg',
    filters_court_priming_svg: '/images/filters-court-priming.svg',
    footer_mail_svg: '/images/footer-mail.svg',
    footer_phone_svg: '/images/footer-phone.svg',
    for_partners_section10_img_png: '/images/for-partners-section10-img.png',
    for_partners_section10_time1_icon_svg: '/images/for-partners-section10-time1-icon.svg',
    for_partners_section10_time2_icon_svg: '/images/for-partners-section10-time2-icon.svg',
    for_partners_section10_time3_icon_svg: '/images/for-partners-section10-time3-icon.svg',
    for_partners_section11_img_png: '/images/for-partners-section11-img.png',
    for_partners_section12_img_png: '/images/for-partners-section12-img.png',
    for_partners_section13_ruble_icon_svg: '/images/for-partners-section13-ruble-icon.svg',
    for_partners_section14_ball_icon_svg: '/images/for-partners-section14-ball-icon.svg',
    for_partners_section14_country_icon_svg: '/images/for-partners-section14-country-icon.svg',
    for_partners_section15_img_png: '/images/for-partners-section15-img.png',
    for_partners_section15_mailto_icon_svg: '/images/for-partners-section15-mailto-icon.svg',
    for_partners_section15_telto_icon_svg: '/images/for-partners-section15-telto-icon.svg',
    for_partners_section2_img_png: '/images/for-partners-section2-img.png',
    for_partners_section2_info_icon_svg: '/images/for-partners-section2-info-icon.svg',
    for_partners_section2_money_icon_svg: '/images/for-partners-section2-money-icon.svg',
    for_partners_section2_network_icon_svg: '/images/for-partners-section2-network-icon.svg',
    for_partners_section2_phone_icon_svg: '/images/for-partners-section2-phone-icon.svg',
    for_partners_section3_arrow_svg: '/images/for-partners-section3-arrow.svg',
    for_partners_section3_img_png: '/images/for-partners-section3-img.png',
    for_partners_section5_ad_icon_svg: '/images/for-partners-section5-ad-icon.svg',
    for_partners_section5_alert_icon_svg: '/images/for-partners-section5-alert-icon.svg',
    for_partners_section5_ball_icon_svg: '/images/for-partners-section5-ball-icon.svg',
    for_partners_section5_card_icon_svg: '/images/for-partners-section5-card-icon.svg',
    for_partners_section5_graphic_icon_svg: '/images/for-partners-section5-graphic-icon.svg',
    for_partners_section5_img_png: '/images/for-partners-section5-img.png',
    for_partners_section5_like_icon_svg: '/images/for-partners-section5-like-icon.svg',
    for_partners_section5_onlinePay_icon_svg: '/images/for-partners-section5-onlinePay-icon.svg',
    for_partners_section5_percent_icon_svg: '/images/for-partners-section5-percent-icon.svg',
    for_partners_section5_star_icon_svg: '/images/for-partners-section5-star-icon.svg',
    for_partners_section6_card_icon_svg: '/images/for-partners-section6-card-icon.svg',
    for_partners_section6_clock_icon_svg: '/images/for-partners-section6-clock-icon.svg',
    for_partners_section6_comment_icon_svg: '/images/for-partners-section6-comment-icon.svg',
    for_partners_section6_community_icon_svg: '/images/for-partners-section6-community-icon.svg',
    for_partners_section6_img_png: '/images/for-partners-section6-img.png',
    for_partners_section6_plus_icon_svg: '/images/for-partners-section6-plus-icon.svg',
    for_partners_section6_racquet_icon_svg: '/images/for-partners-section6-racquet-icon.svg',
    for_partners_section6_star_icon_svg: '/images/for-partners-section6-star-icon.svg',
    for_partners_section7_bg_img_svg: '/images/for-partners-section7-bg-img.svg',
    for_partners_section8_dot_icon_svg: '/images/for-partners-section8-dot-icon.svg',
    for_partners_section8_img_png: '/images/for-partners-section8-img.png',
    geo_icon_svg: '/images/geo-icon.svg',
    geoMap_icon_svg: '/images/geoMap-icon.svg',
    login_icon_svg: '/images/login-icon.svg',
    mainPage_bg_img_png: '/images/mainPage-bg-img.png',
    mainPage_contactSection_bg_img_png: '/images/mainPage-contactSection-bg-img.png',
    mainPage_contactSection_bg_mobile_img_png: '/images/mainPage-contactSection-bg-mobile-img.png',
    menu_close_icon_green_svg: '/images/menu-close-icon-green.svg',
    menu_close_icon_white_svg: '/images/menu-close-icon-white.svg',
    menu_hamburger_svg: '/images/menu-hamburger.svg',
    open_eye_svg: '/images/open-eye.svg',
    personal_data_page_search_opponent_form_info_icon_svg:
      '/images/personal-data-page-search-opponent-form-info-icon.svg',
    sandglass_icon_svg: '/images/sandglass-icon.svg',
    search_court_icon_svg: '/images/search-court-icon.svg',
    search_filter_icon_svg: '/images/search-filter-icon.svg',
    search_geotag_icon_svg: '/images/search-geotag-icon.svg',
    search_icon_svg: '/images/search-icon.svg',
    search_notLike_icon_svg: '/images/search-notLike-icon.svg',
    search_raiting_icon_svg: '/images/search-raiting-icon.svg',
    search_time_icon_svg: '/images/search-time-icon.svg',
    telegram_icon_svg: '/images/telegram-icon.svg',
    tennis_logo_green_svg: '/images/tennis-logo-green.svg',
    tennis_logo_svg: '/images/tennis-logo.svg',
    time_clock_icon_svg: '/images/time-clock-icon.svg',
    viber_icon_svg: '/images/viber-icon.svg',
    whatsup_icon_svg: '/images/whatsup-icon.svg',
  },
  next_svg: '/next.svg',
  robots_txt: '/robots.txt',
  sitemap_0_xml: '/sitemap-0.xml',
  sitemap_xml: '/sitemap.xml',
  vercel_svg: '/vercel.svg',
} as const;

export type StaticPath = typeof staticPath;
