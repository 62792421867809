import { ReactNode } from 'react';
import Link from 'next/link';

interface UnderlineLinkProps {
  href: string;
  children: ReactNode;
}

export const UnderlineLink = (props: UnderlineLinkProps) => {
  const { href, children } = props;
  return (
    <Link href={href} className="underline underline-offset-1">
      {children}
    </Link>
  );
};
