import { ReactNode, useState } from 'react';
import Image from 'next/image';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { csrfToken } from '@/entities/csrf-token';
import { loginMutation } from '@/entities/user/model';

import { FormInput } from '@/shared/components/form-input';
import { Button } from '@/shared/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shared/components/ui/form';

interface LoginFormProps {
  children?: ReactNode;
}

export default function LoginForm(props: LoginFormProps) {
  const { children } = props;
  const [showPassword, setShowPassword] = useState(false);
  const login = useUnit(loginMutation);

  const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

  const formSchema = z.object({
    password: z
      .string()
      .min(6, {
        message: 'Длинна пароля должна быть больше 6 символов',
      })
      .max(50, { message: 'Длинна пароля не может быть больше 50 символов' }),
    phone: z.string().regex(phoneRegex, 'Некорректный номер телефона'),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      phone: '',
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await csrfToken();
    login.start(values);
  };

  return (
    <div className="w-full items-center">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col">
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem className="mt-6 text-description">
                <FormLabel className="ml-3">Номер телефона</FormLabel>
                <FormMessage className="ml-3" />
                <FormControl>
                  <FormInput field={field} type="tel" placeholder="89195068792" classname="pb-1" />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="mt-6 text-description">
                <FormLabel className="ml-3">Пароль</FormLabel>
                <FormMessage className="ml-3" />
                <FormControl>
                  <div>
                    <div className="relative flex">
                      <FormInput field={field} type={showPassword ? 'text' : 'password'} autoComplete="name" />

                      <Button
                        variant="link"
                        className="absolute bottom-[-6px] right-0"
                        size="icon"
                        onClick={() => setShowPassword(!showPassword)}
                        type="button"
                      >
                        <Image
                          alt=""
                          src={showPassword ? '/images/closed-eye.svg' : '/images/open-eye.svg'}
                          width={18}
                          height={18}
                        />
                      </Button>
                    </div>
                    <div className="text-right">{children}</div>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />

          <div className="mt-[60px] flex flex-col items-center justify-between">
            <Button
              type="submit"
              className="mx-auto px-[54px] py-[8px] text-text font-extrabold leading-6 text-white shadow-[0_4px_4px_0_rgba(0,68,55,1)] hover:shadow-[0_4px_4px_0_rgba(0,68,55,0.7)] lg:px-[60px] lg:py-[10px] lg:text-button "
              size="rounded"
              variant="default"
            >
              Войти
            </Button>

            {/* <FormField
              control={form.control}
              name="mobile"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-1 rounded-md p-4 ">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="border-2 border-main data-[state=checked]:bg-main data-[state=checked]:text-title"
                    />
                  </FormControl>
                  <div className="leading-none">
                    <FormLabel className="text-description text-black">Запомнить меня на этом устройстве</FormLabel>
                  </div>
                </FormItem>
              )}
            /> */}
          </div>
        </form>
      </Form>
    </div>
  );
}
