import { clsx, type ClassValue } from 'clsx';
import { createTwc } from 'react-twc';
import { extendTailwindMerge } from 'tailwind-merge';

const fonts = [
  'bigTitle',
  'title',
  'subTitle',
  'button',
  'text',
  'smallText',
  'mobileText',
  'description',
  'mobileDescription',
];
const colors = [
  'main',
  'second',
  'form',
  'textColor',
  'buttonDefault',
  'buttonHover',
  'formDefault',
  'formHover',
  'formActrive',
];

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        {
          text: fonts,
        },
      ],
      'text-color': [
        {
          text: colors,
        },
      ],
      'bg-color': [
        {
          bg: colors,
        },
      ],
      'border-color': [{ border: colors }],
    },
  },
});
export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}

export const twx = createTwc({
  compose: customTwMerge,
  shouldForwardProp: (prop) => !prop.startsWith('_'),
});
