import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useUnit } from 'effector-react';

import CodeConfirmForm from '@/features/code-confirm-form/ui';
import ForgotPasswordChangeForm from '@/features/forgot-password-change-form/ui';
import ForgotPasswordForm from '@/features/forgot-password-form/ui';
import LoginForm from '@/features/login-form/ui';
import SignUpForm from '@/features/signup-form/ui';

import { $isAuthorise } from '@/entities/user/model';

import { Avatar, AvatarFallback, AvatarImage } from '@/shared/components/ui/avatar';
import { Button } from '@/shared/components/ui/button';
import { Separator } from '@/shared/components/ui/separator';
import { Sheet, SheetContent, SheetTrigger } from '@/shared/components/ui/sheet';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/components/ui/tabs';
import { UnderlineLink } from '@/shared/components/underline-link';
import { pagesPath } from '@/shared/config/$path';
import { firstSimbolsFromNameAndSurname } from '@/shared/lib/get-first-symbols';
import { ProfileLink } from '@/shared/lib/profile-link';

import { $mobileAuthFormStage, mobileAuthFormStageChange } from './model';

export const MobileHeader = () => {
  const [showTabs, setShowTabs] = useState(false);
  const [stage, changeFormStage] = useUnit([$mobileAuthFormStage, mobileAuthFormStageChange]);
  const [tabValue, setTabValue] = useState('');
  const isAuthorized = useUnit($isAuthorise);

  return (
    <header className="bg-main ">
      <div className="container flex items-center justify-between py-5">
        <Sheet
          onOpenChange={(open) => {
            open === false && changeFormStage('stage1') && setShowTabs(false);
            setTabValue('');
          }}
        >
          <SheetTrigger>
            <Image alt="" src="/images/menu-hamburger.svg" width={30} height={30} />
          </SheetTrigger>
          <SheetContent
            variant={stage === 'stage1' ? 'white' : 'green'}
            className={`w-full sm:w-3/5 sm:max-w-full ${stage === 'stage1' ? 'bg-main text-white' : ' bg-white text-main'}`}
            side="left"
          >
            <Tabs
              className="mx-auto mt-10 flex h-full w-full flex-col bg-transparent"
              onValueChange={(value) => {
                changeFormStage('stage2');
                setTabValue(value);
                if (value === 'forgotPassword') {
                  setShowTabs(!showTabs);
                }
              }}
              value={tabValue}
            >
              {stage === 'stage1' && (
                <div>
                  <Image alt="Логотип" src="/images/tennis-logo.svg" width={100} height={100} className="mx-auto" />

                  {/* <div className="relative mt-5 flex">
                  <Input
                    className="border-0 border-second bg-second pl-8 text-textColor placeholder:text-textColor focus-visible:ring-0"
                    placeholder="Поиск"
                  />
                  <Button variant="link" className="absolute" size="icon">
                    <Image alt="" src="/images/search-icon.svg" width={18} height={18} />
                  </Button>
                </div> */}

                  <div className="mt-5 flex flex-col space-y-[20px] text-smallText">
                    <Link href="/">Клубы</Link>
                    <Link href="/">Помощь</Link>
                    <Link href="/">Уведомления</Link>
                  </div>
                  <Separator className="my-[15px] w-1/2 bg-[#747474]" />

                  {isAuthorized ? (
                    <ProfileLink />
                  ) : (
                    <div className="flex">
                      <Image alt="" src="/images/login-icon.svg" width={20} height={20} className="mr-1" />
                      <div className="text-smallText">
                        <button
                          className="underline underline-offset-1"
                          onClick={() => {
                            setShowTabs(true);
                            setTabValue('login');
                            changeFormStage('stage2');
                          }}
                        >
                          Войти
                        </button>{' '}
                        или{' '}
                        <button
                          className="underline underline-offset-1"
                          onClick={() => {
                            setShowTabs(true);
                            setTabValue('signup');
                            changeFormStage('stage2');
                          }}
                        >
                          Зарегистрироваться
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {showTabs && (
                <TabsList className="bg-transporent">
                  <div>
                    <TabsTrigger
                      onClick={() => {
                        changeFormStage('stage2');
                        setTabValue('login');
                      }}
                      asChild
                      value={'login'}
                      className="w-[100px] cursor-pointer rounded-l-full text-description text-[#747474] shadow-loginButton data-[state=active]:bg-main data-[state=active]:text-white data-[state=active]:shadow-[0_2px_6px_0_rgba(0,68,55,0.6)] data-[state=active]:hover:cursor-default"
                    >
                      <div className="text-description">Вход</div>
                    </TabsTrigger>

                    <TabsTrigger
                      onClick={() => {
                        setTabValue('signup');
                        changeFormStage('stage2');
                      }}
                      asChild
                      value={'signup'}
                      className="w-[100px] cursor-pointer rounded-r-full text-[#747474] shadow-loginButton data-[state=active]:bg-main data-[state=active]:text-white data-[state=active]:shadow-[0_2px_6px_0_rgba(0,68,55,0.6)] data-[state=active]:hover:cursor-default"
                    >
                      <div className="text-description ">Регистрация</div>
                    </TabsTrigger>
                  </div>
                </TabsList>
              )}

              <TabsContent
                value="login"
                className="h-full max-h-full p-[26px] data-[state=active]:flex data-[state=inactive]:hidden data-[state=active]:flex-col"
              >
                <h2 className="mx-auto text-title font-bold text-main lg:text-bigTitle">Авторизация</h2>

                <div className="mt-10 w-full">
                  <LoginForm>
                    <TabsList className="bg-transporent px-0 pt-0 ">
                      <TabsTrigger asChild value={tabValue} className="shadow-none">
                        <button
                          type="button"
                          className="px-0 pt-0 text-description underline underline-offset-1 shadow-none"
                          onClick={() => {
                            setShowTabs(false);
                            setTabValue('forgotPassword');
                            changeFormStage('stage3');
                          }}
                        >
                          Забыли пароль?
                        </button>
                      </TabsTrigger>
                    </TabsList>
                  </LoginForm>
                </div>
                <Image alt="" src="/images/tennis-logo-green.svg" width={80} height={1} className="mx-auto mt-auto " />
              </TabsContent>

              <TabsContent
                value="signup"
                className="flex h-full flex-col p-[26px] data-[state=active]:flex data-[state=inactive]:hidden data-[state=active]:flex-col"
              >
                <h2 className="mx-auto text-title font-bold text-main lg:text-bigTitle">Регистрация</h2>

                <SignUpForm />
                <div className="mt-4 text-center text-description text-form">
                  Нажимая кнопку <strong>«Зарегистрироваться»</strong> я соглашаюсь с{' '}
                  <UnderlineLink href="">условиями обработки персональных данных.</UnderlineLink>
                </div>

                <Image alt="" src="/images/tennis-logo-green.svg" width={80} height={1} className="mx-auto mt-auto" />
              </TabsContent>

              <TabsContent
                value="forgotPassword"
                className="flex h-full flex-col p-[26px] data-[state=active]:flex data-[state=inactive]:hidden data-[state=active]:flex-col"
              >
                <h2 className="mx-auto mt-[100px] text-center text-title font-bold text-main lg:text-bigTitle">
                  Восстановление пароля
                </h2>
                {stage === 'stage3' && (
                  <ForgotPasswordForm
                    onFormSubmit={() => {
                      changeFormStage('stage4');
                    }}
                  />
                )}

                {stage === 'stage4' && (
                  <div className="mt-10">
                    <CodeConfirmForm
                      onFormSubmit={() => {
                        changeFormStage('stage5');
                      }}
                    />
                  </div>
                )}

                {stage === 'stage5' && (
                  <ForgotPasswordChangeForm
                    onFormSubmit={() => {
                      setTabValue('login');
                    }}
                  />
                )}

                <Image alt="" src="/images/tennis-logo-green.svg" width={80} height={1} className="mx-auto mt-auto" />
              </TabsContent>
            </Tabs>
          </SheetContent>
        </Sheet>

        <Link href="/">
          <Image alt="Логотип" src="/images/tennis-logo.svg" width={100} height={100} />
        </Link>

        <div className="flex "></div>
      </div>
    </header>
  );
};
