import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useUnit } from 'effector-react';

import CodeConfirmForm from '@/features/code-confirm-form/ui';
import ForgotPasswordChangeForm from '@/features/forgot-password-change-form/ui';
import { $forgotPasswordFormStage, forgotPasswordFormStageChange } from '@/features/forgot-password-form/model';
import ForgotPasswordForm from '@/features/forgot-password-form/ui';
import LoginForm from '@/features/login-form/ui';
import SignUpForm from '@/features/signup-form/ui';

import { $isAuthorise } from '@/entities/user/model';

import { Sheet, SheetContent, SheetTrigger } from '@/shared/components/ui/sheet';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/components/ui/tabs';
import { UnderlineLink } from '@/shared/components/underline-link';
import { pagesPath } from '@/shared/config/$path';
import { ProfileLink } from '@/shared/lib/profile-link';

export const Header = () => {
  const [showTabs, setShowTabs] = useState(true);
  const [stage, changeFormStage, isAuthorized] = useUnit([
    $forgotPasswordFormStage,
    forgotPasswordFormStageChange,
    $isAuthorise,
  ]);
  const [tabValue, setTabValue] = useState('login');

  return (
    <header className="bg-main text-white">
      <div className="container flex items-center justify-between py-5">
        <Link href="/">
          <Image alt="Логотип" src="/images/tennis-logo.svg" width={80} height={100} />
        </Link>

        <div className="flex space-x-[48px]">
          <div className="flex items-center space-x-[35px] text-smallText">
            <Link href="/">Клубы</Link>
            <Link href="/">Помощь</Link>
            <Link href={pagesPath.for_partners.$url()}>Партнерам</Link>
          </div>

          {/* <div className="relative flex">
            <Input
              className="w-[240px] border-0 border-second bg-second pl-8 text-textColor placeholder:text-textColor focus-visible:ring-0"
              placeholder="Поиск"
            />
            <Button variant="link" className="absolute" size="icon">
              <Image alt="" src="/images/search-icon.svg" width={18} height={18} />
            </Button>
          </div> */}
        </div>

        {isAuthorized ? (
          <ProfileLink />
        ) : (
          <Sheet
            onOpenChange={(open) => {
              open === false && changeFormStage('stage1') && setShowTabs(true);
            }}
          >
            <div className="flex">
              <Image alt="" src="/images/login-icon.svg" width={20} height={20} className="mr-1" />

              <div className="text-smallText">
                <SheetTrigger className="underline underline-offset-1" onClick={() => setTabValue('login')}>
                  Войти
                </SheetTrigger>{' '}
                или{' '}
                <SheetTrigger className="underline underline-offset-1" onClick={() => setTabValue('signup')}>
                  Зарегистрироваться
                </SheetTrigger>
              </div>
            </div>

            <SheetContent variant="green" className="flex w-[540px] sm:max-w-[540px]">
              <Tabs
                className="mx-auto mt-10 flex w-full flex-col bg-transparent"
                onValueChange={(value) => {
                  changeFormStage('stage1');
                  setTabValue(value);
                  if (value === 'forgotPassword') {
                    setShowTabs(!showTabs);
                  }
                }}
                value={tabValue}
              >
                {showTabs && (
                  <TabsList className="bg-transporent">
                    <div>
                      <TabsTrigger
                        asChild
                        value="login"
                        className="w-[100px] cursor-pointer rounded-l-full text-description text-[#747474] shadow-loginButton data-[state=active]:bg-main data-[state=active]:text-white data-[state=active]:shadow-[0_2px_6px_0_rgba(0,68,55,0.6)] data-[state=active]:hover:cursor-default"
                      >
                        <div className="text-description">Вход</div>
                      </TabsTrigger>

                      <TabsTrigger
                        asChild
                        value="signup"
                        className="w-[100px] cursor-pointer rounded-r-full text-[#747474] shadow-loginButton data-[state=active]:bg-main data-[state=active]:text-white data-[state=active]:shadow-[0_2px_6px_0_rgba(0,68,55,0.6)] data-[state=active]:hover:cursor-default"
                      >
                        <div className="text-description ">Регистрация</div>
                      </TabsTrigger>
                    </div>
                  </TabsList>
                )}

                <TabsContent
                  value="login"
                  className="h-full max-h-full p-[26px] data-[state=active]:flex data-[state=inactive]:hidden data-[state=active]:flex-col"
                >
                  <h2 className="mx-auto text-bigTitle font-bold text-main">Авторизация</h2>
                  <div className="mt-10 w-full">
                    <LoginForm>
                      <TabsList className="bg-transporent px-0 pt-0 ">
                        <TabsTrigger value="forgotPassword" className="hover:cursor-pointer">
                          <div className="px-0 pt-0 text-description underline  underline-offset-1">Забыли пароль?</div>
                        </TabsTrigger>
                      </TabsList>
                    </LoginForm>
                  </div>
                  <Image
                    alt=""
                    src="/images/tennis-logo-green.svg"
                    width={80}
                    height={1}
                    className="mx-auto mt-auto "
                  />
                </TabsContent>

                <TabsContent
                  value="signup"
                  className="flex h-full flex-col p-[26px] data-[state=active]:flex data-[state=inactive]:hidden data-[state=active]:flex-col"
                >
                  <h2 className="mx-auto text-bigTitle font-bold text-main">Регистрация</h2>

                  <SignUpForm />
                  <div className="mt-4 text-center text-description text-form">
                    Нажимая кнопку <strong>«Зарегистрироваться»</strong> я соглашаюсь с{' '}
                    <UnderlineLink href="">условиями обработки персональных данных.</UnderlineLink>
                  </div>

                  <Image alt="" src="/images/tennis-logo-green.svg" width={80} height={1} className="mx-auto mt-auto" />
                </TabsContent>

                <TabsContent
                  value="forgotPassword"
                  className="flex h-full flex-col p-[26px] data-[state=active]:flex data-[state=inactive]:hidden data-[state=active]:flex-col"
                >
                  <h2 className="mx-auto mt-[100px] text-center text-bigTitle font-bold text-main">
                    Восстановление пароля
                  </h2>
                  {stage === 'stage1' && <ForgotPasswordForm onFormSubmit={() => changeFormStage('stage2')} />}

                  {stage === 'stage2' && (
                    <div className="mt-10">
                      <CodeConfirmForm onFormSubmit={() => changeFormStage('stage3')} />
                    </div>
                  )}

                  {stage === 'stage3' && (
                    <ForgotPasswordChangeForm
                      onFormSubmit={() => {
                        setShowTabs(!showTabs);
                        setTabValue('login');
                      }}
                    />
                  )}

                  <Image alt="" src="/images/tennis-logo-green.svg" width={80} height={1} className="mx-auto mt-auto" />
                </TabsContent>
              </Tabs>
            </SheetContent>
          </Sheet>
        )}
      </div>
    </header>
  );
};
