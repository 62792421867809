import { useState } from 'react';
import Image from 'next/image';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { FormInput } from '@/shared/components/form-input';
import { Button } from '@/shared/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shared/components/ui/form';

interface ForgotPasswordChangeFormProps {
  onFormSubmit: () => void;
}
const formSchema = z
  .object({
    password: z
      .string()
      .min(6, {
        message: 'Длинна пароля должна быть больше 6 символов',
      })
      .max(50, { message: 'Длинна пароля не может быть больше 50 символов' }),
    passwordConfirm: z
      .string()
      .min(6, {
        message: 'Длинна пароля должна быть больше 6 символов',
      })
      .max(50, { message: 'Длинна пароля не может быть больше 50 символов' }),
  })

  .superRefine(({ passwordConfirm, password }, ctx) => {
    if (passwordConfirm !== password) {
      ctx.addIssue({
        code: 'custom',
        path: ['passwordConfirm'],
        message: 'Введенные вами пароли не совпадают',
      });
    }
  });

export default function ForgotPasswordChangeForm(props: ForgotPasswordChangeFormProps) {
  const { onFormSubmit } = props;
  const [showPassword, setShowPassword] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    onFormSubmit();
  };

  return (
    <div className="w-full items-center">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="mt-6 text-description">
                <FormLabel className="ml-3">Пароль</FormLabel>
                <FormMessage className="ml-3" />
                <FormControl>
                  <div className="relative flex">
                    <FormInput field={field} type={showPassword ? 'text' : 'password'} autoComplete="name" />

                    <Button
                      variant="link"
                      className="absolute bottom-[-6px] right-0"
                      size="icon"
                      onClick={() => setShowPassword(!showPassword)}
                      type="button"
                    >
                      <Image
                        alt=""
                        src={showPassword ? 'images/closed-eye.svg' : 'images/open-eye.svg'}
                        width={18}
                        height={18}
                      />
                    </Button>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="passwordConfirm"
            render={({ field }) => (
              <FormItem className="mt-6 text-description">
                <FormLabel className="ml-3">Повторите пароль</FormLabel>
                <FormMessage className="ml-3" />
                <FormControl>
                  <div className="relative flex">
                    <FormInput field={field} type={showPassword ? 'text' : 'password'} autoComplete="name" />

                    <Button
                      variant="link"
                      className="absolute bottom-[-6px] right-0"
                      size="icon"
                      onClick={() => setShowPassword(!showPassword)}
                      type="button"
                    >
                      <Image
                        alt=""
                        src={showPassword ? 'images/closed-eye.svg' : 'images/open-eye.svg'}
                        width={18}
                        height={18}
                      />
                    </Button>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />

          <div className="mt-[60px] flex flex-col items-center justify-between">
            <Button
              type="submit"
              className=" mx-auto px-[44px] py-[8px] text-text font-bold leading-6 text-white shadow-[0_4px_4px_0_rgba(0,68,55,1)] hover:shadow-[0_4px_4px_0_rgba(0,68,55,0.7)] lg:px-[60px] lg:py-[10px] lg:text-subTitle lg:font-extrabold "
              size="rounded"
              variant="default"
            >
              Поменять
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
