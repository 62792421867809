export type NetworkExceptionType = 'TooManyRequests' | 'NoInternet' | 'Unauthorized' | 'NotFound' | 'Unknown' | string;

export class NetworkException extends Error {
  type: NetworkExceptionType;

  constructor(type: NetworkExceptionType) {
    super(`${type} network exception has occured`);
    this.type = type;
  }

  toJSON() {
    const alt: Record<string, string> = {};

    Object.getOwnPropertyNames(this).forEach(function (key) {
      // @ts-expect-error this types
      alt[key] = this[key];
    }, this);

    return alt;
  }
}
