import { createEvent, createStore, sample } from 'effector';

export const $mobileAuthFormStage = createStore<string>('stage1');
export const mobileAuthFormStageChange = createEvent<string>();

sample({
  clock: mobileAuthFormStageChange,
  fn: (value: string) => value,
  target: $mobileAuthFormStage,
});
