import { useState } from 'react';
import Image from 'next/image';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { csrfToken } from '@/entities/csrf-token';
import { registerMutation } from '@/entities/user/model';

import { FormInput } from '@/shared/components/form-input';
import { Button } from '@/shared/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shared/components/ui/form';

export default function SignUpForm() {
  const [showPassword, setShowPassword] = useState(false);

  const signUp = useUnit(registerMutation);

  const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

  const formSchema = z
    .object({
      name: z.string().min(1, { message: 'Поле "Имя" не может быть пустым' }),
      phone: z.string().regex(phoneRegex, 'Некорректный номер телефона'),

      password: z
        .string()
        .min(6, {
          message: 'Длинна пароля должна быть больше 6 символов',
        })
        .max(50, { message: 'Длинна пароля не может быть больше 50 символов' }),
      passwordConfirm: z
        .string()
        .min(6, {
          message: 'Длинна пароля должна быть больше 6 символов',
        })
        .max(50, { message: 'Длинна пароля не может быть больше 50 символов' }),
    })

    .superRefine(({ passwordConfirm, password }, ctx) => {
      if (passwordConfirm !== password) {
        ctx.addIssue({
          code: 'custom',
          path: ['passwordConfirm'],
          message: 'Введенные вами пароли не совпадают',
        });
      }
    });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      phone: '',
      password: '',
      passwordConfirm: '',
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await csrfToken();
    signUp.start({
      name: values.name,
      //@ts-expect-error
      last_name: 'Swagger',
      surname: 'Swagger',
      birth: '1961-04-12',
      email: 'swagger@yandex.ru',
      level_id: 1,
      password: values.password,
      password_confirmation: values.passwordConfirm,
      city_id: 1,
      confirm: true,
      phone: values.phone,
    });
  };

  return (
    <div className="w-full items-center ">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="mt-6 text-description">
                <FormLabel className="ml-3">Имя</FormLabel>
                <FormMessage className="ml-3" />
                <FormControl>
                  <FormInput field={field} type="name" placeholder="Ваше имя" classname="pb-1" />
                </FormControl>
              </FormItem>
            )}
          />

          {/* <FormField
            control={form.control}
            name="surname"
            render={({ field }) => (
              <FormItem className="mt-6 text-description">
                <FormLabel className="ml-3">Фамилия</FormLabel>
                <FormMessage className="ml-3" />
                <FormControl>
                  <FormInput field={field} type="name" placeholder="Ваша фамилия" classname="pb-1" />
                </FormControl>
              </FormItem>
            )}
          /> */}

          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem className="mt-6 text-description">
                <FormLabel className="ml-3">Номер телефона</FormLabel>
                <FormMessage className="ml-3" />
                <FormControl>
                  <FormInput field={field} type="tel" placeholder="89195068792" classname="pb-1" />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="mt-6 text-description">
                <FormLabel className="ml-3">Пароль</FormLabel>
                <FormMessage className="ml-3" />
                <FormControl>
                  <div className="relative flex">
                    <FormInput field={field} type={showPassword ? 'text' : 'password'} autoComplete="name" />

                    <Button
                      variant="link"
                      className="absolute bottom-[-6px] right-0"
                      size="icon"
                      onClick={() => setShowPassword(!showPassword)}
                      type="button"
                    >
                      <Image
                        alt=""
                        src={showPassword ? 'images/closed-eye.svg' : 'images/open-eye.svg'}
                        width={18}
                        height={18}
                      />
                    </Button>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="passwordConfirm"
            render={({ field }) => (
              <FormItem className="mt-6 text-description">
                <FormLabel className="ml-3">Пароль</FormLabel>
                <FormMessage className="ml-3" />
                <FormControl>
                  <div className="relative flex">
                    <FormInput field={field} type={showPassword ? 'text' : 'password'} autoComplete="name" />

                    <Button
                      variant="link"
                      className="absolute bottom-[-6px] right-0"
                      size="icon"
                      onClick={() => setShowPassword(!showPassword)}
                      type="button"
                    >
                      <Image
                        alt=""
                        src={showPassword ? 'images/closed-eye.svg' : 'images/open-eye.svg'}
                        width={18}
                        height={18}
                      />
                    </Button>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
          <div className="mt-[60px] flex flex-col items-center justify-between">
            <Button
              type="submit"
              className="mx-auto px-[10px] py-[8px] text-text font-extrabold leading-6 text-white shadow-[0_4px_4px_0_rgba(0,68,55,1)] hover:shadow-[0_4px_4px_0_rgba(0,68,55,0.7)] lg:px-[60px] lg:py-[10px] lg:text-subTitle "
              size="rounded"
              variant="default"
            >
              Зарегистрироваться
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
