import { createJsonMutation, createMutation, createQuery } from '@farfetched/core';
import { createEffect, createEvent, createStore, sample } from 'effector';
import Cookies from 'js-cookie';
import { debug } from 'patronum';

import {
  appointmentMakeAnAppointment,
  AppointmentMakeAnAppointmentBodyAllOf,
  userLogin,
  UserLoginBodyAllOf,
  userLogout,
  userRegister,
  UserRegisterBodyAllOf,
  userShow,
} from '@/shared/api/generated/api.generated';
import { notified, NotifyFxOptions } from '@/shared/lib/effector/notification';
import { clientStarted } from '@/shared/lib/startup';

import { CreateInvoiceData } from './types';

export const $isAuthorise = createStore<boolean>(false);
export const logOut = createEvent();
export const createInvoiceForBookingCourt = createEvent<CreateInvoiceData>();

export const createInvoiceMutation = createMutation({
  effect: createEffect((appointmentMakeAnAppointmentBodyAllOf: AppointmentMakeAnAppointmentBodyAllOf) => {
    return appointmentMakeAnAppointment(appointmentMakeAnAppointmentBodyAllOf);
  }),
});

export const loginMutation = createMutation({
  effect: createEffect((userLoginBodyAllOf: UserLoginBodyAllOf) => {
    return userLogin(userLoginBodyAllOf);
  }),
});

export const logOutMutation = createMutation({
  effect: createEffect(() => {
    return userLogout();
  }),
});

export const registerMutation = createMutation({
  effect: createEffect((userRegisterBodyAllOf: UserRegisterBodyAllOf) => {
    return userRegister(userRegisterBodyAllOf);
  }),
});

sample({
  clock: loginMutation.finished.success,
  fn: (response): NotifyFxOptions => ({
    // @ts-expect-error
    message: response.result?.message,
    type: 'message',
  }),
  target: notified,
});

sample({
  clock: registerMutation.finished.success,
  fn: (response): NotifyFxOptions => ({
    message: response.result?.msg_success,
    type: 'message',
  }),
  target: notified,
});

sample({
  clock: registerMutation.finished.success,
  fn: () => {
    return true;
  },
  target: $isAuthorise,
});

sample({
  clock: loginMutation.finished.failure,
  fn: (response): NotifyFxOptions => ({
    // @ts-expect-error
    message: response.error.json.message,
    type: 'message',
  }),
  target: notified,
});

sample({
  clock: registerMutation.finished.failure,
  fn: (response): NotifyFxOptions => ({
    // @ts-expect-error
    message: response.error.json.message,
    type: 'message',
  }),
  target: notified,
});

sample({
  clock: createInvoiceMutation.finished.failure,
  fn: (response): NotifyFxOptions => ({
    // @ts-expect-error
    message: response.error.json.msg_error,
    type: 'message',
  }),
  target: notified,
});

sample({
  clock: createInvoiceMutation.finished.success,
  fn: (response): NotifyFxOptions => ({
    message: response.result?.msg_success,
    type: 'message',
  }),
  target: notified,
});

export const userQuery = createQuery({
  effect: createEffect(() => {
    return userShow();
  }),
});

sample({
  clock: loginMutation.finished.success,
  fn: () => {
    return true;
  },
  target: $isAuthorise,
});

sample({
  clock: clientStarted,
  fn: () => {
    if (Cookies.get('XSRF-TOKEN')) {
      return true;
    }
    return false;
  },
  target: $isAuthorise,
});

sample({
  clock: logOut,
  target: logOutMutation.start,
});

sample({
  clock: logOutMutation.$finished,
  fn: () => {
    Cookies.remove('XSRF-TOKEN');
    return false;
  },
  target: $isAuthorise,
});

sample({
  clock: logOutMutation.finished.success,
  fn: (response): NotifyFxOptions => ({
    message: response.result?.msg_success,
    type: 'message',
  }),
  target: notified,
});

sample({
  clock: $isAuthorise,
  filter: (authoriseStatus) => authoriseStatus,
  target: userQuery.start,
});

sample({
  clock: createInvoiceForBookingCourt,
  fn: (model) => {
    const intervals = model.intervals.reduce(
      (acc, interval, i) => {
        const [time_start, time_end] = interval.split('-');
        acc[String(i)] = { time_start, time_end };
        return acc;
      },
      {} as Record<string, { time_start: string; time_end: string }>,
    );
    return {
      model: {
        date_start: model.date,
        players_count: model.players,
        court_id: model.courtId,
        inventory: [
          {
            title: 'Ракетки',
            count: 2,
          },
        ],
        intervals,
      },
    };
  },
  target: createInvoiceMutation.start,
});
