import { HTMLInputTypeAttribute } from 'react';

import { Input } from '../ui/input';

interface FormInputProps {
  field?: any;
  type?: HTMLInputTypeAttribute;
  autoComplete?: string;
  placeholder?: string;
  classname?: string;
}

export const FormInput = (props: FormInputProps) => {
  const { field, type, autoComplete, placeholder, classname } = props;
  return (
    <Input
      type={type}
      autoComplete={autoComplete}
      placeholder={placeholder}
      className={` h-5 rounded-none border-0 border-b-[2px] border-[#747474] pl-3 leading-6 text-form shadow-none focus-visible:ring-0 ${type === 'password' ? 'text-title font-extrabold tracking-widest' : 'text-text'} ${classname}`}
      {...field}
    />
  );
};
