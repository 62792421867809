'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/shared/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormMessage } from '@/shared/components/ui/form';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/shared/components/ui/input-otp';

const FormSchema = z.object({
  pin: z.string().min(6, {
    message: 'Код подтверждения должен состоять из 6 символов!',
  }),
});

interface CodeConfirmFormProps {
  onFormSubmit: () => void;
}

export default function CodeConfirmForm(props: CodeConfirmFormProps) {
  const { onFormSubmit } = props;

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      pin: '',
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    onFormSubmit();
  }

  return (
    <div className="w-full items-center">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="pin"
            render={({ field }) => (
              <FormItem>
                <FormDescription className="text-center">
                  Мы отправили код подтверждения на указанный вами электронный адрес
                </FormDescription>
                <FormControl>
                  <InputOTP maxLength={6} {...field} className="mx-auto">
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} className="ml-2.5" />
                      <InputOTPSlot index={2} className="ml-2.5" />
                      <InputOTPSlot index={3} className="ml-2.5" />
                      <InputOTPSlot index={4} className="ml-2.5" />
                      <InputOTPSlot index={5} className="ml-2.5" />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>

                <FormMessage className="text-center" />
              </FormItem>
            )}
          />

          <div className="mt-[30px] flex flex-col items-center justify-between">
            <Button
              type="submit"
              className=" mx-auto px-[44px] py-[8px] text-text font-extrabold leading-6 text-white shadow-[0_4px_4px_0_rgba(0,68,55,1)] hover:shadow-[0_4px_4px_0_rgba(0,68,55,0.7)] lg:px-[60px] lg:py-[10px] lg:text-subTitle "
              size="rounded"
              variant="default"
            >
              Подтвердить
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
