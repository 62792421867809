export const firstSimbolsFromNameAndSurname = (name: string) => {
  if (name === undefined || null) {
    return;
  }
  return name
    .split(' ')
    .map((word) => {
      return word[0];
    })
    .join('');
};
