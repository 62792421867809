/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * BookingCourt API
 * OpenAPI spec version: 1.0.1
 */
import { httpClient } from '../api';
import type { BodyType } from '../api';
export type VerificationCheck200 = {
  ''?: boolean;
};

export type VerificationNotify200 = {
  msg_success: string;
};

export type UserProfileEdit200 = {
  msg_success: string;
};

export type UserProfileEditBodyAllOf = {
  birth: string;
  name: string;
  phone: string;
};

export type UserGetUser200 = {
  pages?: number;
  user: UserGetUser200User;
};

export type UserGetUser200UserLinksItem = {
  active: boolean;
  label: string;
  url: string;
};

export type UserGetUser200UserDataItem = {
  id: number;
  last_name: string;
  name: string;
  surname: string;
};

export type UserGetUser200User = {
  current_page: number;
  data: UserGetUser200UserDataItem[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: number;
  links: UserGetUser200UserLinksItem[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
};

export type UserGetUserBodyAllOf = {
  user_id: number;
};

export type UserIndex200UsersItemLevel = {
  id: number;
  title: string;
};

export type UserIndex200UsersItem = {
  id: number;
  last_name: string;
  level: UserIndex200UsersItemLevel;
  level_id: number;
  name: string;
};

export type UserIndex200 = {
  users: UserIndex200UsersItem[];
};

export type UserLogout200 = {
  msg_success: string;
};

export type UserFavoriteClubs200ClubsItemPlace = {
  lat: string;
  lng: string;
};

export type UserFavoriteClubs200ClubsItemPivot = {
  club_id: number;
  user_id: number;
};

export type UserFavoriteClubs200ClubsItem = {
  address: string;
  city_id: number;
  created_at: string;
  description: string;
  id: number;
  is_premium: number;
  is_standard: number;
  phone: string;
  pivot: UserFavoriteClubs200ClubsItemPivot;
  place: UserFavoriteClubs200ClubsItemPlace;
  preview_image: string;
  rating: string;
  slug: string;
  time_end: string;
  time_start: string;
  title: string;
  updated_at: string;
  user_id: number;
};

export type UserFavoriteClubs200 = {
  clubs: UserFavoriteClubs200ClubsItem[];
};

export type UserShow200User = {
  birth: string;
  city_id: number;
  email: string;
  id: number;
  image: string;
  is_open_profile: number;
  level_id: number;
  name: string;
  phone: string;
};

export type UserShow200UnactualAppointmentsItemInventoryItem = {
  count?: string;
  title?: string;
};

export type UserShow200UnactualAppointmentsItem = {
  date_end: string;
  date_start: string;
  hours: number;
  id: number;
  inventory: UserShow200UnactualAppointmentsItemInventoryItem[];
  time_end: string;
  time_start: string;
};

export type UserShow200ActualAppointmentsItemInventoryItem = {
  count?: string;
  title?: string;
};

export type UserShow200ActualAppointmentsItem = {
  date_end: string;
  date_start: string;
  hours: number;
  id: number;
  inventory: UserShow200ActualAppointmentsItemInventoryItem[];
  time_end: string;
  time_start: string;
};

export type UserShow200 = {
  actual_appointments: UserShow200ActualAppointmentsItem[];
  unactual_appointments: UserShow200UnactualAppointmentsItem[];
  user: UserShow200User;
};

export type UserSetFavoriteClub200 = {
  success_toast_message: string;
};

export type UserSetFavoriteClubBodyAllOf = {
  club_id: number;
};

export type AppointmentMakeAnAppointment200 = {
  msg_success: string;
};

export type AppointmentMakeAnAppointmentBodyAllOfModelInventoryItem = {
  count?: number;
  title?: string;
};

export type AppointmentMakeAnAppointmentBodyAllOfModelIntervals1 = {
  time_end?: string;
  time_start?: string;
};

export type AppointmentMakeAnAppointmentBodyAllOfModelIntervals0 = {
  time_end?: string;
  time_start?: string;
};

export type AppointmentMakeAnAppointmentBodyAllOfModelIntervals = {
  '0'?: AppointmentMakeAnAppointmentBodyAllOfModelIntervals0;
  '1'?: AppointmentMakeAnAppointmentBodyAllOfModelIntervals1;
};

export type AppointmentMakeAnAppointmentBodyAllOfModel = {
  court_id: number;
  date_start?: string;
  intervals: AppointmentMakeAnAppointmentBodyAllOfModelIntervals;
  inventory?: AppointmentMakeAnAppointmentBodyAllOfModelInventoryItem[];
  players_count: number;
};

export type AppointmentMakeAnAppointmentBodyAllOf = {
  model: AppointmentMakeAnAppointmentBodyAllOfModel;
};

export type ReviewSend200 = {
  msg_success: string;
};

export type ReviewSendBodyAllOfReview = {
  club_id: number;
  rating: number;
  text: string;
};

export type ReviewSendBodyAllOf = {
  review: ReviewSendBodyAllOfReview;
};

export type UserRegister200 = {
  msg_success: string;
};

export type UserRegisterBodyAllOf = {
  name: string;
  password: string;
  phone: string;
};

export type PasswordResetResetPassword200 = {
  msg_success: string;
};

export type PasswordResetResetPasswordBodyAllOf = {
  email: string;
  password: string;
  token: string;
};

export type PasswordResetForgotPassword200 = {
  msg_success: string;
};

export type PasswordResetForgotPasswordBodyAllOf = {
  email: string;
};

export type MessageShow200MessagesItemSender = {
  id: number;
  last_name: string;
  name: string;
};

export type MessageShow200MessagesItem = {
  created_at: string;
  id: number;
  message: string;
  recipient_id: number;
  sender: MessageShow200MessagesItemSender;
  sender_id: number;
  updated_at: string;
};

export type MessageShow200 = {
  messages: MessageShow200MessagesItem[];
};

export type MessageIndex200AllOfDialogsItem = {
  last_name: string;
  name: string;
};

export type MessageIndex200AllOf = {
  dialogs?: MessageIndex200AllOfDialogsItem[];
};

export type MessageSend200 = {
  msg_success: string;
};

export type MessageSendBodyAllOf = {
  message: string;
  recipient_id: number;
};

export type UserLogin200 = {
  msg_success: string;
};

export type UserLoginBodyAllOf = {
  password: string;
  phone: string;
};

export type LevelIndex200 = {
  id: number;
  title: string;
};

export type CoopRequestSwitchStatus200 = {
  msg_success: string;
};

export type CoopRequestSwitchStatusBodyAllOf = {
  city_id: number;
  level_id: number;
};

export type CoopRequestSend200 = {
  msg_success: string;
};

export type CoopRequestSendBodyAllOf = {
  recipient_id: number;
};

export type CoopRequestReject200 = {
  msg_success: string;
};

export type CoopRequestAccept200 = {
  msg_success: string;
};

export type CoopRequestIndex200CoopRequestsOutboxItemRecipient = {
  id: number;
  last_name: string;
  name: string;
};

export type CoopRequestIndex200CoopRequestsOutboxItem = {
  created_at: string;
  id: number;
  is_active: number;
  recipient: CoopRequestIndex200CoopRequestsOutboxItemRecipient;
  recipient_id: number;
  sender_id: number;
  updated_at: string;
};

export type CoopRequestIndex200CoopRequestsInboxItemRecipient = {
  id: number;
  last_name: string;
  name: string;
};

export type CoopRequestIndex200CoopRequestsInboxItem = {
  created_at: string;
  id: number;
  is_active: number;
  recipient: CoopRequestIndex200CoopRequestsInboxItemRecipient;
  recipient_id: number;
  sender_id: number;
  updated_at?: string;
};

export type CoopRequestIndex200 = {
  coop_requests_inbox: CoopRequestIndex200CoopRequestsInboxItem[];
  coop_requests_outbox: CoopRequestIndex200CoopRequestsOutboxItem[];
};

export type CoatingIngex200CoatingsItem = {
  description?: string;
  id?: number;
  image?: string;
  title?: string;
};

export type CoatingIngex200 = {
  coatings?: CoatingIngex200CoatingsItem[];
};

export type ClubGetFreeIntervals200 = {
  courts: ClubGetFreeIntervals200CourtsItem[];
};

export type ClubGetFreeIntervals200CourtsItemIntervalsItem = {
  end?: string;
  price?: number;
  start?: string;
};

export type ClubGetFreeIntervals200CourtsItemAppointmentsItem = {
  court_id?: number;
  date_end?: string;
  date_start?: string;
  time_end?: string;
  time_start?: string;
};

export type ClubGetFreeIntervals200CourtsItem = {
  appointments: ClubGetFreeIntervals200CourtsItemAppointmentsItem[];
  id: number;
  intervals: ClubGetFreeIntervals200CourtsItemIntervalsItem[];
  is_duet: number;
  is_open: number;
  max_price: number;
  min_price: number;
  preview_image: string;
  slug: string;
  title: string;
};

export type ClubGetFreeIntervalsParams = {
/**
 * Дата
 */
date: string;
/**
 * Кол-во часов
 */
hours: number;
};

export type ClubShow200ReviewsLinksItem = {
  active?: boolean;
  label?: string;
  url?: string;
};

export type ClubShow200Reviews = {
  current_page: number;
  data: ClubShow200ReviewsDataItem[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: ClubShow200ReviewsLinksItem[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
};

export type ClubShow200 = {
  club: ClubShow200Club;
  courts?: ClubShow200CourtsItem[];
  isFavorite?: boolean;
  reviews?: ClubShow200Reviews;
};

export type ClubShow200ReviewsDataItemUser = {
  id: number;
  name: string;
};

export type ClubShow200ReviewsDataItemPhotosItem = {
  id: number;
  image: string;
  review_id: number;
  url: string;
};

export type ClubShow200ReviewsDataItem = {
  club_id: number;
  created_at: string;
  dislikes: number;
  id: number;
  likes: number;
  photos: ClubShow200ReviewsDataItemPhotosItem[];
  rating: number;
  text: string;
  user: ClubShow200ReviewsDataItemUser;
  user_abbreviation?: string;
  user_id: number;
};

export type ClubShow200CourtsItemCoating = {
  id?: number;
  title?: number;
};

export type ClubShow200CourtsItem = {
  club_id: number;
  coating?: ClubShow200CourtsItemCoating;
  coating_id: number;
  id: number;
  is_duet: number;
  is_open: number;
  preview_image: string;
  size: string;
  slug: string;
  title: string;
};

export type ClubShow200ClubUsersItemPivot = {
  club_id?: number;
  user_id?: number;
};

export type ClubShow200ClubUsersItem = {
  pivot?: ClubShow200ClubUsersItemPivot;
  user_id?: number;
};

export type ClubShow200ClubPlace = {
  lat: string;
  lng: string;
};

export type ClubShow200ClubInventoryItem = {
  club_id?: number;
  count?: string;
  description?: number;
  id?: number;
  price?: number;
  title?: string;
};

export type ClubShow200ClubIntervalsItem = {
  interval_end: string;
  interval_start: string;
  price_weekdays: number;
  price_weekend: number;
};

export type ClubShow200Club = {
  address: string;
  advantages: ClubShow200ClubAdvantagesItem[];
  attachment: ClubShow200ClubAttachmentItem[];
  city: ClubShow200ClubCity;
  city_id: number;
  descriprion?: string;
  id: number;
  intervals: ClubShow200ClubIntervalsItem[];
  inventory: ClubShow200ClubInventoryItem[];
  phone: string;
  place: ClubShow200ClubPlace;
  preview_image: string;
  rating: string;
  slug: string;
  time_end: string;
  time_start: string;
  title: string;
  user_id: number;
  users: ClubShow200ClubUsersItem[];
};

export type ClubShow200ClubCityPlace = {
  lat: string;
  lng: string;
};

export type ClubShow200ClubCity = {
  id: number;
  place: ClubShow200ClubCityPlace;
  title: string;
};

export type ClubShow200ClubAttachmentItemPivot = {
  attachment_id: number;
  attachmentable_id: number;
  attachmentable_type: string;
};

export type ClubShow200ClubAttachmentItem = {
  disk: string;
  extension: string;
  id: number;
  name: string;
  path: string;
  pivot: ClubShow200ClubAttachmentItemPivot;
  relativeUrl: string;
  url: string;
  url_full: string;
};

export type ClubShow200ClubAdvantagesItemPivot = {
  attachment_id: number;
  attachmentable_id: number;
  attachmentable_type: string;
};

export type ClubShow200ClubAdvantagesItem = {
  id: number;
  image: string;
  pivot: ClubShow200ClubAdvantagesItemPivot;
  title: string;
};

export type ClubShowParamsInterval = {
  time_end?: string;
  time_start?: string;
};

export type ClubShowParamsCoating = {
  '0'?: string;
  '1'?: string;
};

export type ClubShowParams = {
/**
 * Параметры для фильтра
 */
params: {
  coating?: ClubShowParamsCoating;
  date?: string;
  interval?: ClubShowParamsInterval;
  is_closed?: string;
  is_duet?: string;
  is_open?: string;
};
};

export type ClubIndex200 = {
  clubs: ClubIndex200Clubs;
};

export type ClubIndex200ClubsPrevPageUrl = {};

export type ClubIndex200ClubsNextPageUrl = {};

export type ClubIndex200ClubsLinksItemUrl = {};

export type ClubIndex200ClubsLinksItem = {
  active: boolean;
  label?: string;
  url: ClubIndex200ClubsLinksItemUrl;
};

export type ClubIndex200ClubsDataItemPlace = {
  lat: string;
  lng: string;
};

export type ClubIndex200ClubsDataItem = {
  address: string;
  city_id: number;
  courts_count: number;
  courts_min_min_price: number;
  description: string;
  id: number;
  phone: string;
  place: ClubIndex200ClubsDataItemPlace;
  preview_image: string;
  rating: string;
  slug: string;
  time_end: string;
  time_start: string;
  title: string;
};

export type ClubIndex200Clubs = {
  current_page: number;
  data: ClubIndex200ClubsDataItem[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: ClubIndex200ClubsLinksItem[];
  next_page_url: ClubIndex200ClubsNextPageUrl;
  path: string;
  per_page: number;
  prev_page_url: ClubIndex200ClubsPrevPageUrl;
  to: number;
  total: number;
};

export type ClubIndexParamsCoating = {
  '0'?: string;
  '1'?: string;
};

export type ClubIndexParamsAdvantages = {
  '0'?: string;
  '1'?: string;
};

export type ClubIndexParams = {
/**
 * Параметры для фильтра
 */
params: {
  addHours?: number;
  advantages?: ClubIndexParamsAdvantages;
  coating?: ClubIndexParamsCoating;
  date?: string;
  'hours[hoursMax]'?: string;
  'hours[hoursMin]'?: string;
  is_closed?: string;
  is_duet?: string;
  is_open?: string;
  'price[priceMax]'?: string;
  'price[priceMin]'?: string;
  'rating[ratingMax]'?: number;
  'rating[ratingMin]'?: number;
  time?: string;
};
};

export type CityIndexForCoop200CitiesItemPlace = {
  lat: string;
  lng: string;
};

export type CityIndexForCoop200CitiesItem = {
  id: number;
  place: CityIndexForCoop200CitiesItemPlace;
  title: string;
};

export type CityIndexForCoop200 = {
  cities: CityIndexForCoop200CitiesItem[];
};

export type CityIndex200CitiesItemPlace = {
  lat: string;
  lng: string;
};

export type CityIndex200CitiesItem = {
  id: number;
  place: CityIndex200CitiesItemPlace;
  title: string;
};

export type CityIndex200 = {
  cities: CityIndex200CitiesItem[];
};

export type CRMLogin200User = {
  name?: string;
};

export type CRMLogin200 = {
  message?: string;
  status?: boolean;
  token?: string;
  user?: CRMLogin200User;
};

export type CRMLoginBodyAllOf = {
  email?: string;
  password?: string;
};

export type CRMFindUser200 = {
  email?: string;
  last_name?: string;
  name?: string;
  phone?: string;
  surname?: string;
};

export type CRMFindUserByPhone200UserLinksItem = {
  active?: boolean;
  label?: string;
  url?: string;
};

export type CRMFindUserByPhone200UserDataItem = {
  id?: number;
  last_name?: string;
  name?: string;
  surname?: string;
};

export type CRMFindUserByPhone200User = {
  current_page?: number;
  data?: CRMFindUserByPhone200UserDataItem[];
  first_page_url?: string;
  from?: number;
  last_page?: number;
  last_page_url?: number;
  links?: CRMFindUserByPhone200UserLinksItem[];
  next_page_url?: string;
  path?: string;
  per_page?: number;
  prev_page_url?: string;
  to?: number;
  total?: number;
};

export type CRMFindUserByPhone200 = {
  pages?: number;
  user?: CRMFindUserByPhone200User;
};

export type CRMGetUser200User = {
  club_id?: number;
  created_at?: string;
  email?: string;
  id?: number;
  name?: string;
  updated_at?: string;
};

export type CRMGetUser200 = {
  user?: CRMGetUser200User;
};

export type CRMDelete200CourtsAppointmentsItemAppointmentsItem = {};

export type CRMDelete200CourtsAppointmentsItem = {
  appointments: CRMDelete200CourtsAppointmentsItemAppointmentsItem[];
  id: number;
  title: string;
};

export type CRMDelete200 = {
  courts_appointments: CRMDelete200CourtsAppointmentsItem[];
  msg_success: string;
};

export type CRMEdit200 = {
  msg_success: string;
};

export type CRMEditBodyAllOfModelInventoryItem = {
  count?: number;
  title?: string;
};

export type CRMEditBodyAllOfModel = {
  comment: string;
  court_id: number;
  date_start: string;
  email?: string;
  inventory: CRMEditBodyAllOfModelInventoryItem[];
  name: string;
  phone: string;
  players_count: number;
  surcharge: number;
  time_end: string;
  time_start: string;
};

export type CRMEditBodyAllOf = {
  model: CRMEditBodyAllOfModel;
};

export type CRMStore200 = {
  msg_success: string;
};

export type CRMStoreBodyAllOfModelInventoryItem = {
  count?: number;
  title?: string;
};

export type CRMStoreBodyAllOfModel = {
  comment: string;
  court_id: number;
  date_start: string;
  email?: string;
  inventory: CRMStoreBodyAllOfModelInventoryItem[];
  name: string;
  phone: string;
  players_count: number;
  surcharge: number;
  time_end: string;
  time_start: string;
};

export type CRMStoreBodyAllOf = {
  model: CRMStoreBodyAllOfModel;
};

export type CRMIndex200 = {
  courts_appointments: CRMIndex200CourtsAppointmentsItem[];
};

export type CRMIndex200CourtsAppointmentsItemAppointmentsItemInventory = {
  count?: number;
  title?: string;
};

export type CRMIndex200CourtsAppointmentsItemAppointmentsItem = {
  comment: string;
  court_id: number;
  date_end: string;
  date_start: string;
  email: string;
  id: number;
  inventory: CRMIndex200CourtsAppointmentsItemAppointmentsItemInventory;
  name: string;
  payment_method: string;
  phone: string;
  players_count: number;
  price: string;
  status: string;
  surcharge: number;
  time_end?: string;
  time_start?: string;
  user_id: number;
};

export type CRMIndex200CourtsAppointmentsItem = {
  appointments: CRMIndex200CourtsAppointmentsItemAppointmentsItem[];
  id: number;
  title: string;
};

export type CRMIndexParams = {
/**
 * Дата
 */
date?: string;
};

export type CRMCourtIndex200WorkingTime = {
  time_end?: string;
  time_start?: string;
};

export type CRMCourtIndex200InventoryItem = {
  club_id?: number;
  count?: string;
  description?: number;
  id?: number;
  price?: number;
  title?: string;
};

export type CRMCourtIndex200CourtsItem = {
  club_id?: number;
  id?: number;
  title?: string;
};

export type CRMCourtIndex200 = {
  courts: CRMCourtIndex200CourtsItem[];
  id: number;
  inventory: CRMCourtIndex200InventoryItem[];
  title: string;
  working_time: CRMCourtIndex200WorkingTime;
};

export type CRMClubIndex200ClubsInventoryItem = {
  club_id?: number;
  count?: string;
  description?: number;
  id?: number;
  price?: number;
  title?: string;
};

export type CRMClubIndex200Clubs = {
  id: number;
  inventory: CRMClubIndex200ClubsInventoryItem[];
  time_end: string;
  time_start: string;
  title: string;
};

export type CRMClubIndex200 = {
  clubs: CRMClubIndex200Clubs;
};

export type AppealSend200 = {
  msg_success: string;
};

export type AppealSendBodyAllOf = {
  name: string;
  phone: string;
};

export type AdvantageIndex200AdvantagesItem = {
  id: number;
  title: string;
};

export type AdvantageIndex200 = {
  advantages: AdvantageIndex200AdvantagesItem[];
};





  /**
 * @summary Список преимуществ
 */
export const advantageIndex = (
    
 ) => {
      return httpClient<AdvantageIndex200>(
      {url: `/api/advantages`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Отправка заявки с формы обратной связи
 */
export const appealSend = (
    appealSendBodyAllOf: BodyType<AppealSendBodyAllOf>,
 ) => {
      return httpClient<AppealSend200>(
      {url: `/api/send-appeal`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: appealSendBodyAllOf
    },
      );
    }
  
/**
 * @summary Получение информации о клубе
 */
export const cRMClubIndex = (
    
 ) => {
      return httpClient<CRMClubIndex200>(
      {url: `/api/crm/club`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Получение информации о клубе и его кортах
 */
export const cRMCourtIndex = (
    
 ) => {
      return httpClient<CRMCourtIndex200>(
      {url: `/api/crm/courts`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Вывод записей на корты
 */
export const cRMIndex = (
    params?: CRMIndexParams,
 ) => {
      return httpClient<CRMIndex200>(
      {url: `/api/crm/appointments`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @summary Создание записи в CRM
 */
export const cRMStore = (
    cRMStoreBodyAllOf: BodyType<CRMStoreBodyAllOf>,
 ) => {
      return httpClient<CRMStore200>(
      {url: `/api/crm/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cRMStoreBodyAllOf
    },
      );
    }
  
/**
 * @summary Редактирование записи на корт
 */
export const cRMEdit = (
    cRMEditBodyAllOf: BodyType<CRMEditBodyAllOf>,
    id?: number,
 ) => {
      return httpClient<CRMEdit200>(
      {url: `/api/crm/${id}/edit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cRMEditBodyAllOf
    },
      );
    }
  
/**
 * @summary Удаление записи на корт
 */
export const cRMDelete = (
    id?: number,
 ) => {
      return httpClient<CRMDelete200>(
      {url: `/api/crm/${id}/delete`, method: 'DELETE'
    },
      );
    }
  
/**
 * @summary Получение авторизованного менеджера клуба
 */
export const cRMGetUser = (
    
 ) => {
      return httpClient<CRMGetUser200>(
      {url: `/api/crm/user`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Получение обычного пользователя по номеру телефона
 */
export const cRMFindUserByPhone = (
    phone?: number,
 ) => {
      return httpClient<CRMFindUserByPhone200>(
      {url: `/api/crm/user/${phone}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Получение ФИО, e-mail и номера телефона обычного пользователя по ID
 */
export const cRMFindUser = (
    id?: number,
 ) => {
      return httpClient<CRMFindUser200>(
      {url: `/api/crm/user/${id}/get`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Авторизация в CRM
 */
export const cRMLogin = (
    cRMLoginBodyAllOf: BodyType<CRMLoginBodyAllOf>,
 ) => {
      return httpClient<CRMLogin200>(
      {url: `/api/crm/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cRMLoginBodyAllOf
    },
      );
    }
  
/**
 * @summary Выход из учетной записи CRM
 */
export const cRMLogout = (
    
 ) => {
      return httpClient<void>(
      {url: `/api/crm/logout`, method: 'POST'
    },
      );
    }
  
/**
 * @summary Список городов, в которых есть как минимум один клуб, имеющий один и больше кортов
 */
export const cityIndex = (
    
 ) => {
      return httpClient<CityIndex200>(
      {url: `/api/cities`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Список городов для поиска апонента
 */
export const cityIndexForCoop = (
    
 ) => {
      return httpClient<CityIndexForCoop200>(
      {url: `/api/cities-for-coop`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Список клубов
 */
export const clubIndex = (
    city: string,
    params: ClubIndexParams,
 ) => {
      return httpClient<ClubIndex200>(
      {url: `/api/clubs/${city}`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @summary Подробная информация о клубе, кортах и отзывах
 */
export const clubShow = (
    slug: string,
    params: ClubShowParams,
 ) => {
      return httpClient<ClubShow200>(
      {url: `/api/clubs/${slug}/show`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @summary Список кортов с незанятыми интервалами
 */
export const clubGetFreeIntervals = (
    clubSlug: string,
    params: ClubGetFreeIntervalsParams,
 ) => {
      return httpClient<ClubGetFreeIntervals200>(
      {url: `/api/${clubSlug}/get-free-intervals`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @summary Список покрытий корта
 */
export const coatingIngex = (
    
 ) => {
      return httpClient<CoatingIngex200>(
      {url: `/api/coatings`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Список заявок на совместную игру
 */
export const coopRequestIndex = (
    
 ) => {
      return httpClient<CoopRequestIndex200>(
      {url: `/api/coop-requests`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Подтверждение заявки на совместную игру
 */
export const coopRequestAccept = (
    id: number,
 ) => {
      return httpClient<CoopRequestAccept200>(
      {url: `/api/coop-request/${id}/accept`, method: 'POST'
    },
      );
    }
  
/**
 * @summary Отклонение заявки на совместную игру
 */
export const coopRequestReject = (
    id: number,
 ) => {
      return httpClient<CoopRequestReject200>(
      {url: `/api/coop-request/${id}/reject`, method: 'POST'
    },
      );
    }
  
/**
 * @summary Отправка заявки на совместную игру
 */
export const coopRequestSend = (
    coopRequestSendBodyAllOf: BodyType<CoopRequestSendBodyAllOf>,
 ) => {
      return httpClient<CoopRequestSend200>(
      {url: `/api/coop-request`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: coopRequestSendBodyAllOf
    },
      );
    }
  
/**
 * @summary Переключение статуса поиска апонента
 */
export const coopRequestSwitchStatus = (
    coopRequestSwitchStatusBodyAllOf: BodyType<CoopRequestSwitchStatusBodyAllOf>,
 ) => {
      return httpClient<CoopRequestSwitchStatus200>(
      {url: `/api/coop-request/switch-status`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: coopRequestSwitchStatusBodyAllOf
    },
      );
    }
  
/**
 * @summary Список уровней
 */
export const levelIndex = (
    
 ) => {
      return httpClient<LevelIndex200>(
      {url: `/api/levels`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Авторизация
 */
export const userLogin = (
    userLoginBodyAllOf: BodyType<UserLoginBodyAllOf>,
 ) => {
      return httpClient<UserLogin200>(
      {url: `/api/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userLoginBodyAllOf
    },
      );
    }
  
/**
 * @summary Отправка сообщения
 */
export const messageSend = (
    messageSendBodyAllOf: BodyType<MessageSendBodyAllOf>,
 ) => {
      return httpClient<MessageSend200>(
      {url: `/api/send`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: messageSendBodyAllOf
    },
      );
    }
  
/**
 * @summary Вывод диалогов
 */
export const messageIndex = (
    
 ) => {
      return httpClient<MessageIndex200AllOf>(
      {url: `/api/dialogs`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Вывод диалога
 */
export const messageShow = (
    id: string,
 ) => {
      return httpClient<MessageShow200>(
      {url: `/api/dialogs/${id}/show`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Отправка сообщение на эл. почту для восстановления пароля
 */
export const passwordResetForgotPassword = (
    passwordResetForgotPasswordBodyAllOf: BodyType<PasswordResetForgotPasswordBodyAllOf>,
 ) => {
      return httpClient<PasswordResetForgotPassword200>(
      {url: `/api/forgot-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: passwordResetForgotPasswordBodyAllOf
    },
      );
    }
  
/**
 * @summary Восстановление пароля
 */
export const passwordResetResetPassword = (
    passwordResetResetPasswordBodyAllOf: BodyType<PasswordResetResetPasswordBodyAllOf>,
 ) => {
      return httpClient<PasswordResetResetPassword200>(
      {url: `/api/reset-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: passwordResetResetPasswordBodyAllOf
    },
      );
    }
  
/**
 * @summary Регистрация нового пользователя
 */
export const userRegister = (
    userRegisterBodyAllOf: BodyType<UserRegisterBodyAllOf>,
 ) => {
      return httpClient<UserRegister200>(
      {url: `/api/register`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userRegisterBodyAllOf
    },
      );
    }
  
/**
 * @summary Отправка отзыва
 */
export const reviewSend = (
    reviewSendBodyAllOf: BodyType<ReviewSendBodyAllOf>,
 ) => {
      return httpClient<ReviewSend200>(
      {url: `/api/send-review`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: reviewSendBodyAllOf
    },
      );
    }
  
/**
 * @summary Создание записи на корт
 */
export const appointmentMakeAnAppointment = (
    appointmentMakeAnAppointmentBodyAllOf: BodyType<AppointmentMakeAnAppointmentBodyAllOf>,
 ) => {
      return httpClient<AppointmentMakeAnAppointment200>(
      {url: `/api/make-an-appointment`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: appointmentMakeAnAppointmentBodyAllOf
    },
      );
    }
  
/**
 * @summary Отмена записи на корт
 */
export const appointmentCancel = (
    id: number,
 ) => {
      return httpClient<void>(
      {url: `/api/appointment/${id}/cancel`, method: 'POST'
    },
      );
    }
  
/**
 * @summary Добавить клуб в избранное
 */
export const userSetFavoriteClub = (
    userSetFavoriteClubBodyAllOf: BodyType<UserSetFavoriteClubBodyAllOf>,
 ) => {
      return httpClient<UserSetFavoriteClub200>(
      {url: `/api/set-favorite-club`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userSetFavoriteClubBodyAllOf
    },
      );
    }
  
/**
 * @summary Профиль пользователя
 */
export const userShow = (
    
 ) => {
      return httpClient<UserShow200>(
      {url: `/api/profile`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Список клубов, добавленных в избранное
 */
export const userFavoriteClubs = (
    
 ) => {
      return httpClient<UserFavoriteClubs200>(
      {url: `/api/favorite-clubs`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Выход из учетной записи
 */
export const userLogout = (
    
 ) => {
      return httpClient<UserLogout200>(
      {url: `/api/user/logout`, method: 'POST'
    },
      );
    }
  
/**
 * @summary Получение списка пользователей для совместной игры
 */
export const userIndex = (
    
 ) => {
      return httpClient<UserIndex200>(
      {url: `/api/users`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Получение пользователя по ID (JSON)
 */
export const userGetUser = (
    userGetUserBodyAllOf: BodyType<UserGetUserBodyAllOf>,
 ) => {
      return httpClient<UserGetUser200>(
      {url: `/api/get-user`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userGetUserBodyAllOf
    },
      );
    }
  
/**
 * @summary Редактирование профиля
 */
export const userProfileEdit = (
    userProfileEditBodyAllOf: BodyType<UserProfileEditBodyAllOf>,
 ) => {
      return httpClient<UserProfileEdit200>(
      {url: `/api/profile/edit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userProfileEditBodyAllOf
    },
      );
    }
  
/**
 * @summary Верификация
 */
export const verificationVerify = (
    id: number,
    hash: string,
 ) => {
      return httpClient<void>(
      {url: `/api/email/verify/${id}/${hash}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Отправка письма с просьбой подтверждения эл. почты
 */
export const verificationNotify = (
    
 ) => {
      return httpClient<VerificationNotify200>(
      {url: `/api/email/verification-notification`, method: 'POST'
    },
      );
    }
  
/**
 * @summary Проверка верификации
 */
export const verificationCheck = (
    
 ) => {
      return httpClient<VerificationCheck200>(
      {url: `/api/check-verification`, method: 'GET'
    },
      );
    }
  
export type AdvantageIndexResult = NonNullable<Awaited<ReturnType<typeof advantageIndex>>>
export type AppealSendResult = NonNullable<Awaited<ReturnType<typeof appealSend>>>
export type CRMClubIndexResult = NonNullable<Awaited<ReturnType<typeof cRMClubIndex>>>
export type CRMCourtIndexResult = NonNullable<Awaited<ReturnType<typeof cRMCourtIndex>>>
export type CRMIndexResult = NonNullable<Awaited<ReturnType<typeof cRMIndex>>>
export type CRMStoreResult = NonNullable<Awaited<ReturnType<typeof cRMStore>>>
export type CRMEditResult = NonNullable<Awaited<ReturnType<typeof cRMEdit>>>
export type CRMDeleteResult = NonNullable<Awaited<ReturnType<typeof cRMDelete>>>
export type CRMGetUserResult = NonNullable<Awaited<ReturnType<typeof cRMGetUser>>>
export type CRMFindUserByPhoneResult = NonNullable<Awaited<ReturnType<typeof cRMFindUserByPhone>>>
export type CRMFindUserResult = NonNullable<Awaited<ReturnType<typeof cRMFindUser>>>
export type CRMLoginResult = NonNullable<Awaited<ReturnType<typeof cRMLogin>>>
export type CRMLogoutResult = NonNullable<Awaited<ReturnType<typeof cRMLogout>>>
export type CityIndexResult = NonNullable<Awaited<ReturnType<typeof cityIndex>>>
export type CityIndexForCoopResult = NonNullable<Awaited<ReturnType<typeof cityIndexForCoop>>>
export type ClubIndexResult = NonNullable<Awaited<ReturnType<typeof clubIndex>>>
export type ClubShowResult = NonNullable<Awaited<ReturnType<typeof clubShow>>>
export type ClubGetFreeIntervalsResult = NonNullable<Awaited<ReturnType<typeof clubGetFreeIntervals>>>
export type CoatingIngexResult = NonNullable<Awaited<ReturnType<typeof coatingIngex>>>
export type CoopRequestIndexResult = NonNullable<Awaited<ReturnType<typeof coopRequestIndex>>>
export type CoopRequestAcceptResult = NonNullable<Awaited<ReturnType<typeof coopRequestAccept>>>
export type CoopRequestRejectResult = NonNullable<Awaited<ReturnType<typeof coopRequestReject>>>
export type CoopRequestSendResult = NonNullable<Awaited<ReturnType<typeof coopRequestSend>>>
export type CoopRequestSwitchStatusResult = NonNullable<Awaited<ReturnType<typeof coopRequestSwitchStatus>>>
export type LevelIndexResult = NonNullable<Awaited<ReturnType<typeof levelIndex>>>
export type UserLoginResult = NonNullable<Awaited<ReturnType<typeof userLogin>>>
export type MessageSendResult = NonNullable<Awaited<ReturnType<typeof messageSend>>>
export type MessageIndexResult = NonNullable<Awaited<ReturnType<typeof messageIndex>>>
export type MessageShowResult = NonNullable<Awaited<ReturnType<typeof messageShow>>>
export type PasswordResetForgotPasswordResult = NonNullable<Awaited<ReturnType<typeof passwordResetForgotPassword>>>
export type PasswordResetResetPasswordResult = NonNullable<Awaited<ReturnType<typeof passwordResetResetPassword>>>
export type UserRegisterResult = NonNullable<Awaited<ReturnType<typeof userRegister>>>
export type ReviewSendResult = NonNullable<Awaited<ReturnType<typeof reviewSend>>>
export type AppointmentMakeAnAppointmentResult = NonNullable<Awaited<ReturnType<typeof appointmentMakeAnAppointment>>>
export type AppointmentCancelResult = NonNullable<Awaited<ReturnType<typeof appointmentCancel>>>
export type UserSetFavoriteClubResult = NonNullable<Awaited<ReturnType<typeof userSetFavoriteClub>>>
export type UserShowResult = NonNullable<Awaited<ReturnType<typeof userShow>>>
export type UserFavoriteClubsResult = NonNullable<Awaited<ReturnType<typeof userFavoriteClubs>>>
export type UserLogoutResult = NonNullable<Awaited<ReturnType<typeof userLogout>>>
export type UserIndexResult = NonNullable<Awaited<ReturnType<typeof userIndex>>>
export type UserGetUserResult = NonNullable<Awaited<ReturnType<typeof userGetUser>>>
export type UserProfileEditResult = NonNullable<Awaited<ReturnType<typeof userProfileEdit>>>
export type VerificationVerifyResult = NonNullable<Awaited<ReturnType<typeof verificationVerify>>>
export type VerificationNotifyResult = NonNullable<Awaited<ReturnType<typeof verificationNotify>>>
export type VerificationCheckResult = NonNullable<Awaited<ReturnType<typeof verificationCheck>>>
