import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { FormInput } from '@/shared/components/form-input';
import { Button } from '@/shared/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/shared/components/ui/form';

interface ForgotPasswordFormProps {
  onFormSubmit: () => void;
}

export default function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const { onFormSubmit } = props;

  const formSchema = z.object({
    email: z.string().email({ message: 'Некорректная почта' }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    onFormSubmit();
  };

  return (
    <div className="w-full items-center">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="mt-6 text-description">
                <FormDescription className="mb-2 ml-3">
                  Введите свой Email и мы отправим вам письмо с проверкой
                </FormDescription>
                <FormLabel className="ml-3">Email</FormLabel>
                <FormMessage className="ml-3" />
                <FormControl>
                  <FormInput field={field} type="email" placeholder="example@mail.ru" classname="pb-1" />
                </FormControl>
              </FormItem>
            )}
          />

          <div className="mt-[60px] flex flex-col items-center justify-between">
            <Button
              type="submit"
              className=" mx-auto px-[60px] py-[10px] text-subTitle font-extrabold leading-6 text-white shadow-[0_4px_4px_0_rgba(0,68,55,1)] hover:shadow-[0_4px_4px_0_rgba(0,68,55,0.7)] "
              size="rounded"
              variant="default"
            >
              Отправить
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
