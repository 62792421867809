import Link from 'next/link';
import { useUnit } from 'effector-react';

import { userQuery } from '@/entities/user/model';

import { Avatar, AvatarFallback, AvatarImage } from '@/shared/components/ui/avatar';
import { pagesPath } from '@/shared/config/$path';

import { firstSimbolsFromNameAndSurname } from '../get-first-symbols';

export const ProfileLink = () => {
  const user = useUnit(userQuery);

  return (
    <Link href={pagesPath.personal_account.$url()} className="flex items-center space-x-[5px]">
      <Avatar className="h-[30px] w-[30px] text-[12px]">
        <AvatarImage src={user?.data?.user.image} />
        <AvatarFallback className="bg-[#61ADA6]">
          {firstSimbolsFromNameAndSurname(user.data?.user.name!)}
        </AvatarFallback>
      </Avatar>
      <div>{user?.data?.user.name}</div>
    </Link>
  );
};
