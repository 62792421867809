import { ReactNode } from 'react';

import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';
import { MobileHeader } from '@/widgets/mobile-header';

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout = (props: MainLayoutProps) => {
  const { children } = props;
  return (
    <div className="flex h-full flex-col">
      <header>
        <div className="hidden lg:block">
          <Header />
        </div>
        <div className="block lg:hidden">
          <MobileHeader />
        </div>
      </header>

      <div className="flex-1 bg-main">{children}</div>

      <Footer />
    </div>
  );
};
