import Image from 'next/image';
import Link from 'next/link';

import { Button } from '@/shared/components/ui/button';
import { UnderlineLink } from '@/shared/components/underline-link';

export const Footer = () => {
  return (
    <footer className="border-t-[1px] border-white bg-main py-[18px] text-white">
      <div className="container flex flex-col items-center justify-between text-mobileText md:flex-row md:text-text ">
        <div className="flex flex-row items-center space-x-[6px] md:flex-col md:space-x-0">
          <div className="mr-auto flex items-center">
            <Image alt="" src="/images/footer-phone.svg" width={20} height={20} className="mr-1" />
            <a href="tel:89939314314">8-9939-314-314</a>
          </div>

          <div>
            <div className="mr-auto flex items-center">
              <Image alt="" src="/images/footer-mail.svg" width={20} height={20} className="mr-1" />
              <a href="mailto:sales@bookingcourt.ru">sales@bookingcourt.ru</a>
            </div>
          </div>
        </div>

        <div>
          <Link href="/" className="hidden md:block">
            <Button variant="link">
              <Image alt="Логотип" src="/images/tennis-logo.svg" width={100} height={40} />
            </Button>
          </Link>

          <div className="mt-[10px] flex justify-between space-x-[8px] md:hidden">
            <Link href="/">
              <Button variant="link">
                <Image alt="" src="/images/telegram-icon.svg" width={24} height={24} />
              </Button>
            </Link>

            <Link href="/">
              <Button variant="link">
                <Image alt="" src="/images/whatsup-icon.svg" width={24} height={24} />
              </Button>
            </Link>

            <Link href="/">
              <Button variant="link">
                <Image alt="" src="/images/viber-icon.svg" width={24} height={24} />
              </Button>
            </Link>
          </div>

          <div className="mt-[10px] hidden justify-between space-x-[8px] md:flex">
            <Link href="/">
              <Button variant="link">
                <Image alt="" src="/images/telegram-icon.svg" width={24} height={24} />
              </Button>
            </Link>

            <Link href="/">
              <Button variant="link">
                <Image alt="" src="/images/whatsup-icon.svg" width={24} height={24} />
              </Button>
            </Link>

            <Link href="/">
              <Button variant="link">
                <Image alt="" src="/images/viber-icon.svg" width={24} height={24} />
              </Button>
            </Link>
          </div>
        </div>

        <div className="flex flex-col space-y-1 py-[18px] text-center text-mobileDescription md:py-0 md:text-right">
          <UnderlineLink href="">Пользовательское соглашение</UnderlineLink>
          <UnderlineLink href="">Защита персональных данных</UnderlineLink>
          <UnderlineLink href="">Договор публичной оферты</UnderlineLink>
          <UnderlineLink href="">Реквизиты</UnderlineLink>
        </div>

        <Link href="/" className="block md:hidden">
          <Button variant="link">
            <Image alt="Логотип" src="/images/tennis-logo.svg" width={100} height={40} />
          </Button>
        </Link>
      </div>
    </footer>
  );
};
