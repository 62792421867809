import { createEvent, createStore, sample } from 'effector';

export const $forgotPasswordFormStage = createStore<string>('stage1');
export const forgotPasswordFormStageChange = createEvent<string>();

sample({
  clock: forgotPasswordFormStageChange,
  fn: (value: string) => value,
  target: $forgotPasswordFormStage,
});
